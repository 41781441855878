import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import NavigationView from "./NavigationView";
import { useContext, useEffect } from "react";
import { AppStoreContext } from "service";
import { routes } from "config";
import { includes } from "lodash";

function RootView() {
  const { authStore, k3900Store } = useContext(AppStoreContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!authStore.loggedIn) {
      navigate(routes.signIn.path);
    }
  }, []);

  useEffect(() => {
    const pathname = location.pathname;
    const isExam = includes(pathname, "/exam");
    k3900Store.setExam(isExam);
  }, [location]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <NavigationView />
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>
        <Outlet />
      </Box>
    </Box>
  );
}

export default RootView;
