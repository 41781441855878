import { Stack, StackProps } from "@mui/material";
import { createContext, useContext } from "react";
import { FormStore } from "service";

export type FormViewProps = StackProps & {
  store: FormStore;
  children?: React.ReactNode | React.ReactNode[];
};

// Create the context
const FormViewContext = createContext(new FormStore());

// Create a custom hook for using the FormContext
export const useFormViewContext = () => {
  return useContext(FormViewContext);
};

function FormView({ store, children, sx, ...props }: FormViewProps) {
  return (
    <FormViewContext.Provider value={store}>
      <Stack
        spacing={3}
        sx={[
          { display: "flex", flexDirection: "column", width: "100%" },
          sx ?? false,
        ].flat(2)}
        {...props}
      >
        {children}
      </Stack>
    </FormViewContext.Provider>
  );
}

export default FormView;
