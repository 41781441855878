import { Box, Paper } from "@mui/material";
import Draggable from "react-draggable";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import IconButtonView from "main/components/common/IconButtonView";
import TextBoxView from "main/components/common/TextBoxView";

type DraggableModalProps = {
  onClose: (src: string) => void;
  imageSrc: string;
  index: number;
};

function DraggableModal({ onClose, imageSrc, index }: DraggableModalProps) {
  return (
    <Draggable cancel=".no-drag">
      <Paper
        sx={{
          position: "absolute",
          top: "20%",
          left: "20%",
          bgcolor: "background.paper",
          boxShadow: 24,
          width: "fit-content",
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            cursor: "move",
            px: 3,
            backgroundColor: "black.300",
          }}
          id={`#draggable-modal-title-${index}`}
        >
          <TextBoxView locale="pages.exam.preview_image" />
          <IconButtonView onClick={() => onClose(imageSrc)} iconType="close" />
        </Box>
        <TransformWrapper>
          {({ zoomIn, zoomOut }) => (
            <Box className="no-drag">
              <TransformComponent>
                <img src={imageSrc} alt="Selected" />
              </TransformComponent>
              <Box
                sx={{
                  backgroundColor: "black.400",
                  display: "flex",
                  flexDirection: "row",
                  px: 3,
                }}
              >
                <TextBoxView locale="pages.exam.zoom" />
                <IconButtonView
                  iconType="controlPoint"
                  sx={{ color: "primary.main" }}
                  onClick={() => zoomIn()}
                />
                <IconButtonView
                  iconType="remove"
                  sx={{ color: "primary.main" }}
                  onClick={() => zoomOut()}
                />
              </Box>
            </Box>
          )}
        </TransformWrapper>
      </Paper>
    </Draggable>
  );
}

export default DraggableModal;
