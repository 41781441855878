export type URLType = "users" | "puService";

const BaseURL = {
  users: "https://dev.mauiimaging.com/api/v1",
  puService: "https://pu1.dev.mauiimaging.com/api/v1",
};

enum Url {
  // Auth
  signIn = "auth/login",
  //Patients
  hospitalPatients = "hospital/{0}/patients",
  allPatients = "patient",
  patientDetails = "patient/{0}",
  patientMedia = "patient/{0}/patientFiles",
  processingUnitAvailable = "processing-unit/available",
  loadDataSet = "load_dataset",
  taskStatus = "task_status",
  cancelTask = "cancel",
}

export { BaseURL, Url };
