import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { ButtonView, TextBoxView, PatientImageCard, LoaderDialog } from "main";
import { cardHeaderStyle, baseCardStyle, cardContentStyles } from "config";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { AppStoreContext, MediaEntity } from "service";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { groupedPatientMedia } from "utils";
import { flatMap } from "lodash";

function PatientPage() {
  const { patientStore, puStore } = useContext(AppStoreContext);
  const { patientId } = useParams();
  const {
    isPUAvailable,
    puName,
    taskStatus,
    taskStep,
    isTaskLoading,
    taskProgress,
    timerTime,
    estimatedTimeRemaining,
    isLoadingDataSet,
    clearTaskStatus,
    cancelTask,
    isEstimateTimeCalculating,
  } = puStore;
  const navigate = useNavigate();

  useEffect(() => {
    patientStore.fetchPatientDetail(patientId as string);
    patientStore.fetchPatientExams(patientId as string);
  }, [patientId]);

  const handleClick = useCallback(
    async (s3Key: string) => {
      if (!isPUAvailable) {
        return;
      }

      const taskID = await puStore.loadDataset(s3Key);
      puStore.getTaskStatus(taskID as string);
    },
    [isPUAvailable]
  );

  useEffect(() => {
    if (taskStatus === "SUCCESS") {
      navigate(
        generatePath("/patients/:patientId/exam", {
          patientId: patientId as string,
        })
      );
      clearTaskStatus();
    }
  }, [taskStatus]);

  const onCancelTask = () => cancelTask();

  const { patient, patientMedia } = patientStore;
  const groupedMedia = useMemo(
    () => groupedPatientMedia(patientMedia as MediaEntity[]),
    [patientMedia]
  );
  return (
    <Box sx={{ px: 3, height: "100%" }}>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Typography variant="h4" color="secondary">
          {patient?.fullName}
        </Typography>
        <Stack
          spacing={1}
          direction="row"
          useFlexGap
          sx={{ flexWrap: "wrap", height: "100%", overflow: "hidden" }}
        >
          <Card
            sx={{
              ...baseCardStyle,
              width: { xs: "100%", sm: "19%" },
            }}
          >
            <CardHeader
              sx={{
                ...cardHeaderStyle,
              }}
              title={
                <TextBoxView
                  locale="pages.patient.patient_details"
                  sx={{ fontSize: "0.9rem" }}
                />
              }
            />
            <CardContent sx={{ ...cardContentStyles }}>
              <Stack direction="row" spacing={5}>
                <Typography sx={{ color: "black.50" }}>Patient ID</Typography>
                <Typography sx={{ color: "secondary.main" }}>
                  {patient?.shortId}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
          <Card
            sx={{
              ...baseCardStyle,
              width: { xs: "100%", sm: "79%", pb: 1 },
            }}
          >
            <CardHeader
              sx={{
                ...cardHeaderStyle,
              }}
              title={
                <TextBoxView
                  locale="pages.patient.saved_media"
                  sx={{ fontSize: "0.9rem" }}
                />
              }
            />
            <CardContent
              sx={{
                ...cardContentStyles,
                paddingBottom: 1,
              }}
            >
              <Stack
                spacing={1}
                direction="row"
                useFlexGap
                sx={{
                  flexWrap: "wrap",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    overflow: "scroll",
                    height: "100%",
                  }}
                >
                  {Object.entries(groupedMedia).map(([date, exam]) => {
                    return Object.entries(exam).map(([examName, media]) => {
                      return (
                        <PatientImageCard
                          key={`${date}-${examName}`}
                          date={date}
                          examName={examName}
                          notes=""
                          mediaList={flatMap(media)}
                          onImageClick={handleClick}
                        />
                      );
                    });
                  })}
                </Box>
                <Card
                  sx={{
                    ...baseCardStyle,
                    width: { xs: "100%", sm: "25%" },
                    height: "95%",
                  }}
                >
                  <CardHeader
                    sx={{
                      ...cardHeaderStyle,
                    }}
                    title={
                      <TextBoxView
                        locale="pages.patient.add_notes"
                        sx={{ fontSize: "0.9rem" }}
                      />
                    }
                  />
                  <CardContent
                    sx={{
                      ...cardContentStyles,
                      justifyContent: "space-between",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box sx={{ flex: 1 }}></Box>
                    <ButtonView title="general.save" sx={{ mb: 2 }} />
                  </CardContent>
                </Card>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
        <LoaderDialog
          open={isTaskLoading}
          progress={taskProgress}
          puName={puName}
          step={taskStep}
          timerTime={timerTime}
          estimatedTimeRemaining={estimatedTimeRemaining}
          onClose={onCancelTask}
          isCalculatingLoadTime={isEstimateTimeCalculating}
        />
        {isLoadingDataSet && (
          <CircularProgress
            sx={{ position: "absolute", top: "50%", left: "50%" }}
          />
        )}
      </Box>
    </Box>
  );
}

export default observer(PatientPage);
