// import { useLocation } from "react-router-dom";
import { PinturaEditor } from "@pqina/react-pintura";
import { Box } from "@mui/material";
import {
  // editor
  locale_en_gb,
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,

  // plugins
  setPlugins,
  plugin_crop,
  plugin_resize,
  plugin_finetune,
  plugin_filter,
  plugin_annotate,
  markup_editor_defaults,
  plugin_filter_defaults,
  plugin_crop_locale_en_gb,
  plugin_finetune_locale_en_gb,
  plugin_finetune_defaults,
  plugin_filter_locale_en_gb,
  plugin_annotate_locale_en_gb,
  markup_editor_locale_en_gb,
  plugin_retouch_locale_en_gb,
  plugin_resize_locale_en_gb,
  plugin_fill,
  PinturaNode,
  findNode,
  PinturaNodeOptions,
} from "@pqina/pintura";

setPlugins(
  plugin_crop,
  plugin_finetune,
  plugin_filter,
  plugin_annotate,
  plugin_resize,
  plugin_fill
);
import { useContext } from "react";
import { AppStoreContext } from "service";
import { last } from "lodash";
import { Observer } from "mobx-react";
import "@pqina/pintura/pintura.css";

interface ImageEditorProps {
  onSave: (editedImage: string) => void;
}

const editorDefaults = {
  imageReader: createDefaultImageReader(),
  imageWriter: createDefaultImageWriter(),
  shapePreprocessor: createDefaultShapePreprocessor(),
  ...plugin_finetune_defaults,
  ...plugin_filter_defaults,
  ...markup_editor_defaults,
  locale: {
    ...locale_en_gb,
    ...plugin_crop_locale_en_gb,
    ...plugin_finetune_locale_en_gb,
    ...plugin_filter_locale_en_gb,
    ...plugin_annotate_locale_en_gb,
    ...markup_editor_locale_en_gb,
    ...plugin_retouch_locale_en_gb,
    ...plugin_resize_locale_en_gb,
  },
};

function PatientImageEditor({ onSave }: ImageEditorProps) {
  //   const location = useLocation();
  //   const { imageUrl } = location.state;
  const { k3900Store } = useContext(AppStoreContext);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Observer>
        {() => (
          <PinturaEditor
            key={last(k3900Store.imageChunks)}
            {...editorDefaults}
            src={last(k3900Store.imageChunks)}
            onProcess={({ dest }) => {
              const imgUrl = URL.createObjectURL(dest);
              onSave(imgUrl);
            }}
            willRenderToolbar={(toolbar: PinturaNode[]) => {
              const exportButton = findNode("export", toolbar) as PinturaNode;
              const exportButtonOptions = exportButton[2] as PinturaNodeOptions;
              exportButtonOptions.label = "Capture";
              return toolbar;
            }}
          />
        )}
      </Observer>
    </Box>
  );
}

export default PatientImageEditor;
