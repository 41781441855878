import { Slider, SliderProps } from "@mui/material";
import { Observer } from "mobx-react";
import { useFormViewContext } from "../../common/FormView";
import { useContext, useEffect, useRef, useState } from "react";
import { AppStoreContext } from "service";
import { isArray, last } from "lodash";

type TGSSliderViewProps = SliderProps & {
  name?: string;
};

function TGSSliderView({
  min,
  max,
  defaultValue = 0,
  name = "",
  ...rest
}: TGSSliderViewProps) {
  const formStore = useFormViewContext();
  const { k3900Store } = useContext(AppStoreContext);
  const [sliderValue, setSliderValue] = useState<number | number[]>(
    defaultValue
  );
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleChange = (event: Event, value: number | number[]) => {
    if (isArray(value)) {
      value = last(value) || 0;
    }
    setSliderValue(value);

    // Clear the previous timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout
    timeoutRef.current = setTimeout(() => {
      const index = Number(name.slice(-1));
      if (isArray(value)) {
        value = last(value) || 0;
      }
      formStore.setValue(name as string, value as number);
      k3900Store.setParameters(value, index);
      console.log("tgc value:", value);
    }, 300);
  };

  useEffect(() => {
    const value = (defaultValue ?? min) as number;
    if (value !== undefined && value !== null) {
      formStore.setValue(name as string, value);
    }
  }, [formStore, defaultValue]);

  return (
    <Observer>
      {() => (
        <Slider
          defaultValue={defaultValue}
          min={min}
          max={max}
          onChange={handleChange}
          marks={[{ value: min as number }, { value: max as number }]}
          value={sliderValue}
          {...rest}
        />
      )}
    </Observer>
  );
}

export default TGSSliderView;
