import { Slider, SliderProps } from "@mui/material";
import { Observer } from "mobx-react";
import { useFormViewContext } from "./FormView";
import { useEffect, useRef, useState } from "react";
import { isArray, last } from "lodash";
import { reaction } from "mobx";

type SliderViewProps = SliderProps & {
  name?: string;
  onChange?: (value: number) => void;
};

function SliderView({
  min,
  max,
  defaultValue = 0,
  name = "",
  onChange,
  ...rest
}: SliderViewProps) {
  const formStore = useFormViewContext();
  const [sliderValue, setSliderValue] = useState<number | number[]>(
    defaultValue
  );
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleChange = (event: Event, value: number | number[]) => {
    if (isArray(value)) {
      value = last(value) || 0;
    }
    setSliderValue(value);

    // Clear the previous timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout
    timeoutRef.current = setTimeout(() => {
      if (isArray(value)) {
        value = last(value) || 0;
      }
      formStore.setValue(name as string, value as number);
      onChange?.(value);
    }, 300);
  };

  useEffect(() => {
    const value = (defaultValue ?? min) as number;
    if (value !== undefined && value !== null) {
      formStore.setValue(name as string, value);
    }
  }, [formStore, defaultValue]);

  reaction(
    () => formStore?.getValue(name),
    (value) => {
      setSliderValue(Number(value));
    }
  );

  return (
    <Observer>
      {() => (
        <Slider
          defaultValue={defaultValue}
          min={min}
          max={max}
          onChange={handleChange}
          marks={[{ value: min as number }, { value: max as number }]}
          value={sliderValue}
          {...rest}
        />
      )}
    </Observer>
  );
}

export default SliderView;
