import { Box, Divider, Tab, Tabs } from "@mui/material";
import {
  AccordionView,
  FormView,
  IconView,
  TextBoxView,
  useFormViewContext,
  ControlWithInputView,
  ButtonView,
  TGSSliderView,
} from "main";
import { useState } from "react";

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ p: 1, width: "100%", overflow: "scroll" }}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

function ImageControlsPanel() {
  const [value, setValue] = useState<number>(1);
  const formStore = useFormViewContext();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        height: "100%",
        backgroundColor: "black.500",
      }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        sx={{ width: 40, height: "100%", backgroundColor: "black.100" }}
      >
        <Tab
          icon={<IconView type="file" />}
          aria-label="home"
          {...a11yProps(0)}
        />
        <Tab
          icon={<IconView type="tune" />}
          aria-label="settings"
          {...a11yProps(1)}
        />
        <Tab
          icon={<IconView type="equalize" />}
          aria-label="profile"
          {...a11yProps(2)}
        />
      </Tabs>
      <TabPanel value={value} index={0}></TabPanel>
      <TabPanel value={value} index={1}>
        <TextBoxView locale="pages.exam.image_controls" />
        <Divider sx={{ borderColor: "black.300", mb: 1 }} />
        <AccordionView title="pages.exam.controls.tgc">
          <TGSSliderView
            min={-1}
            max={1.0}
            defaultValue={0}
            step={0.001}
            name="tgc0"
          />
          <TGSSliderView
            min={-1}
            max={1.0}
            defaultValue={0}
            step={0.001}
            name="tgc1"
          />
          <TGSSliderView
            min={-1}
            max={1.0}
            defaultValue={0}
            step={0.001}
            name="tgc2"
          />
          <TGSSliderView
            min={-1}
            max={1.0}
            defaultValue={0}
            step={0.001}
            name="tgc3"
          />
          <TGSSliderView
            min={-1}
            max={1.0}
            defaultValue={0}
            step={0.001}
            name="tgc4"
          />
          <TGSSliderView
            min={-1}
            max={1.0}
            defaultValue={0}
            step={0.001}
            name="tgc5"
          />
          <TGSSliderView
            min={-1}
            max={1.0}
            defaultValue={0}
            step={0.001}
            name="tgc6"
          />
          <TGSSliderView
            min={-1}
            max={1.0}
            defaultValue={0}
            step={0.001}
            name="tgc7"
          />
          <TGSSliderView
            min={-1}
            max={1.0}
            defaultValue={0}
            step={0.001}
            name="tgc8"
          />
          <ButtonView
            title="general.center"
            sx={{
              height: "32px",
              width: 40,
              margin: "0 auto",
              backgroundColor: "black.400",
              borderColor: "primary.main",
              borderWidth: 1,
              borderStyle: "solid",
              textTransform: "capitalize",
            }}
            textBoxStyle={{ color: "secondary.light" }}
          />
        </AccordionView>
        <AccordionView title="pages.exam.controls.control1">
          <FormView store={formStore}>
            <ControlWithInputView
              title="pages.exam.controls.dynamic_range"
              name="brightness"
              min={-0.5}
              max={0.5}
              step={0.1}
              defaultValue={0}
            />
            <ControlWithInputView
              title="pages.exam.controls.fine_adjust"
              name="dynamicRange"
              min={30}
              max={100}
              defaultValue={60}
              step={1}
            />
            <ControlWithInputView
              title="pages.exam.controls.zoom"
              name="zoom"
              min={-1}
              max={1.0}
              defaultValue={0}
              step={0.001}
              hideInput={true}
            />
          </FormView>
        </AccordionView>
        <AccordionView title="pages.exam.controls.control2">
          <FormView store={formStore}>
            <ControlWithInputView
              title="pages.exam.controls.tx"
              name="tx"
              min={1}
              max={3}
              step={1}
            />
            <ControlWithInputView
              title="pages.exam.controls.rx"
              name="rx"
              min={1}
              max={3}
              step={1}
            />
            <ControlWithInputView
              title="pages.exam.controls.speed_of_sound"
              name="speedOfSound"
              suffix="m/s"
              min={1200}
              max={1800}
              step={5}
            />
          </FormView>
        </AccordionView>
        <AccordionView title="pages.exam.controls.display_controls">
          -
        </AccordionView>
        <AccordionView title="pages.exam.controls.graymap_type">
          <Box
            sx={{
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <ButtonView title="+" sx={{ px: 1, height: "30px" }} />
            <ButtonView title="-" sx={{ px: 1, height: "30px" }} />
          </Box>
        </AccordionView>
        <AccordionView title="pages.exam.controls.equalization_type">
          <Box
            sx={{
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <ButtonView title="+" sx={{ px: 1, height: "30px" }} />
            <ButtonView title="-" sx={{ px: 1, height: "30px" }} />
          </Box>
        </AccordionView>
        <AccordionView title="pages.exam.controls.gamma">
          <FormView store={formStore}>
            <ControlWithInputView
              title="pages.exam.controls.gamma"
              name="gamma"
              min={0.5}
              max={4.5}
              step={0.1}
            />
          </FormView>
        </AccordionView>
        <AccordionView title="pages.exam.controls.image_filters">
          -
        </AccordionView>
        <AccordionView title="pages.exam.controls.capture_and_analysis">
          -
        </AccordionView>
      </TabPanel>
      <TabPanel value={value} index={2}></TabPanel>
    </Box>
  );
}

export default ImageControlsPanel;
