import { RpcError, Status } from "grpc-web";
import { isEmpty } from "lodash";
import { makeAutoObservable, reaction } from "mobx";
import {
  ResponseMsg,
  LoginRequest,
  Credentials,
  BlankRequest,
  ImageRequest,
  ImageChunk,
  LogoutRequest,
  FloatArrayParam,
  BeamformerParameter,
  BeamformerParametersRequest,
} from "proto/k3900_pb";
import { Any } from "google-protobuf/google/protobuf/any_pb";
import { isValidUUID } from "utils";
import { BeamformerClient } from "proto";

type StreamType = "play" | "pause";

interface k3900StoreProps {
  client: BeamformerClient;
  memId?: string;
  imageChunks: string[];
  originImageChunks: string[];
  isExam: boolean;
  streamType: StreamType;
  isFirstStream: boolean;
  bufferSize: number;
  isVideoConverting: boolean;
  login: () => void;
  keepAlive: () => void;
  streamData: () => void;
  pingServer: (callback?: (response: ResponseMsg) => void) => void;
  setParameters: (value: number, index: number) => void;
}

interface ProtobufMessage {
  serializeBinary(): Uint8Array;
}

export class k3900Store implements k3900StoreProps {
  client: BeamformerClient;
  memId?: string;
  imageChunks: string[] = [];
  originImageChunks: string[] = [];
  originImageChunks2: string[][] = [];
  isExam: boolean = false;
  streamType: StreamType = "play";
  isFirstStream: boolean = true;
  bufferSize: number = 0;
  isVideoConverting: boolean = false;

  constructor() {
    this.client = new BeamformerClient(
      `${process.env.REACT_APP_k3900_API_URL}:${process.env.REACT_APP_k3900_API_PORT}`
    );
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => this.isExam, // Function to observe
      (newValue) => {
        if (!this.memId && newValue) {
          this.login();
        } else {
          this.logout();
        }
      }
    );
  }

  login(callback?: (response: ResponseMsg) => void) {
    const loginRequest = new LoginRequest();
    const credentials = new Credentials();
    credentials.setUsername(process.env.REACT_APP_k3900_USERNAME || "");
    credentials.setPassword(process.env.REACT_APP_k3900_PASSWORD || "");
    loginRequest.setCredentials(credentials);
    loginRequest.setRo(false);
    this.client
      .userLogin(loginRequest)
      .then((response: ResponseMsg) => {
        const msg = response.getMsg();
        console.log(msg);
        if (!isValidUUID(msg) && this.isExam) {
          this.login();
          return;
        }
        this.setMem(msg);
        this.keepAlive();
        this.streamData();
        callback?.(response);
      })
      .catch((error: RpcError) => {
        console.log(`login: ${error.message}`);
        if (this.isExam) {
          this.login();
        }
      });
  }

  keepAlive() {
    const interval = setInterval(() => {
      if (!this.memId) {
        clearInterval(interval);
        return;
      }
      const blankRequest = new BlankRequest();
      blankRequest.setMemid(this.memId);
      this.client
        .keepAlive(blankRequest)
        .then((response: ResponseMsg) => {
          let msg = response.getMsg();
          if (isEmpty(msg)) {
            msg = "keep alive";
          }
          console.log(msg);
        })
        .catch((error: RpcError) => {
          console.log(`keep alive: ${error.message}`);
          clearInterval(interval);
        });
    }, 5000);
  }

  streamData() {
    if (!this.memId) {
      return;
    }
    const imageRequest = new ImageRequest();
    imageRequest.setMemid(this.memId);
    imageRequest.setJpg(true);
    imageRequest.setTime(0);
    const stream = this.client.sendBeamformedImageStream(imageRequest);

    stream.on("error", (err: RpcError) => {
      console.log(`stream error: ${err.message}`);
    });

    stream.on("status", (status: Status) => {
      const { details } = status;
      if (details !== "Permission Denied") {
        this.streamData();
      }
      console.log(`stream status: ${status.details}`);
    });

    stream.on("data", (response: ImageChunk) => {
      const { pixels, pbBufferSize } =
        response.toObject() as ImageChunk.AsObject;
      this.bufferSize = pbBufferSize;

      const imageSrc = `data:image/jpeg;base64,${pixels}`;
      console.log(`imageChunks,${this.imageChunks.length}`);

      if (this.originImageChunks.length < pbBufferSize) {
        this.appendOriginImageChunk(imageSrc);
      }

      if (this.imageChunks.length === pbBufferSize) {
        this.clearImageChunks();
      }

      if (this.streamType === "play") {
        const lastOrigin = this.originImageChunks[this.imageChunks.length];
        this.appendImageChunk(lastOrigin);
      }
    });
  }

  setParameters(value: number, index: number) {
    if (!this.memId) {
      return;
    }
    const paramBuilder = new FloatArrayParam();
    paramBuilder.setIndex(index);
    paramBuilder.setValue(value);
    paramBuilder.setAbsolute(true);

    const bfparamBuilder = new BeamformerParameter();
    bfparamBuilder.setName("tgc");
    bfparamBuilder.setValue(
      this.packAny(paramBuilder, "type.googleapis.com/k3900.FloatArrayParam")
    );

    const request = new BeamformerParametersRequest();
    request.setMemid(this.memId);
    request.addParameters(bfparamBuilder, index);
    this.client.setBeamformerParameters(request).then((resp) => {
      this.setIsFirstStream(true);
      this.clearOriginImageChunks();
      this.clearImageChunks();
      // this.streamData();
      console.log(resp);
    });
  }

  clearImageChunks() {
    this.imageChunks = [];
  }

  clearOriginImageChunks() {
    this.originImageChunks = [];
  }

  pingServer(callback?: (response: ResponseMsg) => void) {
    if (!this.memId) {
      return;
    }
    const pingRequest = new BlankRequest();
    pingRequest.setMemid(this.memId);
    this.client
      .pingServer(pingRequest)
      .then((response) => {
        callback?.(response);
      })
      .catch((error: RpcError) => {
        console.log(`ping server: ${error.message}`);
      });
  }

  logout() {
    if (!this.memId) {
      return;
    }
    const logoutRequest = new LogoutRequest();
    logoutRequest.setMemid(this.memId);
    this.client
      .userLogout(logoutRequest)
      .then((response) => {
        this.setMem(undefined);
        let msg = response.getMsg();
        if (isEmpty(msg)) {
          msg = "logout";
        }
        console.log(msg);
      })
      .catch((error: RpcError) => {
        console.log(`logout: ${error.message}`);
      });
  }

  packAny(message: ProtobufMessage, typeUrl: string): Any {
    const any = new Any();
    any.pack(message.serializeBinary(), typeUrl);
    return any;
  }

  toggleVideoConverting() {
    this.isVideoConverting = !this.isVideoConverting;
  }

  private setMem(id?: string) {
    this.memId = id;
  }

  setExam(state: boolean) {
    this.isExam = state;
  }

  pauseStream(state: boolean) {
    if (state) {
      this.streamType = "pause";
    } else {
      this.streamType = "play";
    }
  }

  private appendImageChunk(src: string) {
    this.imageChunks.push(src);
  }

  private appendOriginImageChunk(src: string) {
    this.originImageChunks.push(src);
  }

  private setIsFirstStream(state: boolean) {
    this.isFirstStream = state;
  }
}
