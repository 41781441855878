import {
  Box,
  Card,
  CardHeader,
  Stack,
  IconButton,
  CardActions,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import { cardHeaderStyle, baseCardStyle } from "config";
import { Observer } from "mobx-react";
import { useContext } from "react";
import { AppStoreContext } from "service";
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import PauseCircleFilledRoundedIcon from "@mui/icons-material/PauseCircleFilledRounded";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { last } from "lodash";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import { FileData } from "@ffmpeg/ffmpeg/dist/esm/types";

function ExamStreamView() {
  const { examStore, k3900Store } = useContext(AppStoreContext);
  const ffmpeg = new FFmpeg();

  const convertImagesToMp4 = async () => {
    if (k3900Store.originImageChunks.length === 0) {
      return;
    }
    k3900Store.toggleVideoConverting();

    await ffmpeg.load();

    // Load the FFmpeg.wasm library
    if (!ffmpeg.loaded) {
      await ffmpeg.load();
    }

    // Prepare the images and add them to FFmpeg's virtual file system
    for (let i = 0; i < k3900Store.originImageChunks.length; i++) {
      await ffmpeg.writeFile(
        `img${i}.jpg`,
        await fetchFile(k3900Store.originImageChunks[i])
      );
    }

    await ffmpeg.exec([
      "-framerate",
      "4", // Frame rate (4 frame per second) - adjust as needed
      "-i",
      "img%d.jpg", // Input format
      "-c:v",
      "libx264", // Video codec
      "-pix_fmt",
      "yuv420p", // Pixel format
      "output.mp4", // Output filename
    ]);
    const data: FileData = await ffmpeg.readFile("output.mp4");

    // // Create a blob URL so it can be downloaded/viewed
    const videoBlob = new Blob([data], { type: "video/mp4" });
    const blobVideoUrl = URL.createObjectURL(videoBlob);

    examStore.appendFilteredVideo(blobVideoUrl);
    k3900Store.toggleVideoConverting();
  };

  const captureImage = () => {
    const lastImageChunk = last(k3900Store.imageChunks);
    if (lastImageChunk) {
      examStore.handleSavePreset(lastImageChunk);
    }
  };

  return (
    <Card
      sx={{
        ...baseCardStyle,
        flex: 4,
      }}
    >
      <CardHeader
        sx={{
          ...cardHeaderStyle,
        }}
        title={
          <Stack
            direction="row"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              sx={{ color: "secondary.main", width: 40 }}
              onClick={captureImage}
            >
              <CameraAltOutlinedIcon />
            </IconButton>
            <Observer>
              {() => (
                <Box>
                  {k3900Store.isVideoConverting && (
                    <CircularProgress size="20px" />
                  )}
                  {!k3900Store.isVideoConverting && (
                    <IconButton
                      sx={{ color: "secondary.main", width: 40 }}
                      onClick={convertImagesToMp4}
                    >
                      <VideocamOutlinedIcon />
                    </IconButton>
                  )}
                </Box>
              )}
            </Observer>
          </Stack>
        }
      />
      <Observer>
        {() => (
          <CardMedia
            component="img"
            image={last(k3900Store.imageChunks)}
            sx={{ py: 2, height: "84%", objectFit: "contain" }}
          />
        )}
      </Observer>
      <CardActions
        sx={{
          height: "8%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Observer>
          {() => (
            <Box>
              {k3900Store.streamType === "pause" && (
                <PlayCircleFilledRoundedIcon
                  fontSize="large"
                  sx={{ color: "white" }}
                  onClick={() => {
                    k3900Store.pauseStream(false);
                  }}
                />
              )}
              {k3900Store.streamType === "play" && (
                <PauseCircleFilledRoundedIcon
                  fontSize="large"
                  sx={{ color: "white" }}
                  onClick={() => {
                    k3900Store.pauseStream(true);
                  }}
                />
              )}
            </Box>
          )}
        </Observer>
      </CardActions>
    </Card>
  );
}

export default ExamStreamView;
