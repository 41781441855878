import {
  Box,
  Card,
  CardContent,
  CardHeader,
  ImageList,
  ImageListItem,
  Stack,
  Checkbox,
  CardActions,
} from "@mui/material";
import { cardHeaderStyle, baseCardStyle, cardContentStyles } from "config";
import {
  ButtonView,
  DraggableModal,
  ExamStreamView,
  ImageControlsPanel,
  TextBoxView,
} from "main";
import { Observer } from "mobx-react";
import { useContext } from "react";
import { AppStoreContext } from "service";

function ExamPage() {
  const { examStore } = useContext(AppStoreContext);

  return (
    <Box sx={{ height: "100%", maxHeight: "100%" }}>
      <Stack
        spacing={1}
        direction="row"
        useFlexGap
        sx={{ flexWrap: "wrap", height: "100%", p: 3 }}
      >
        <Card
          sx={{
            ...baseCardStyle,
            flex: 1,
          }}
        >
          <CardHeader
            sx={{
              ...cardHeaderStyle,
            }}
            title={
              <TextBoxView
                locale="pages.exam.media"
                sx={{ fontSize: "0.9rem" }}
              />
            }
          />
          <CardContent
            sx={{
              ...cardContentStyles,
              overflow: "scroll",
              paddingBottom: "0!important",
              height: "80%",
            }}
          >
            <Observer>
              {() => {
                const {
                  filteredImages,
                  toggleImageSelection,
                  isImageSelected,
                  toggleOpenedModals,
                  openedModals,
                } = examStore;
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <ImageList cols={1}>
                      {examStore.filteredVideos.map((videoSrc) => (
                        <ImageListItem
                          key={`media-${videoSrc}`}
                          sx={{
                            position: "relative",
                            width: "180px",
                            height: "180px!important",
                            border: 1,
                            borderColor: "black.300",
                            borderRadius: 2,
                            mb: 2,
                            margin: "0 auto",
                          }}
                        >
                          <video
                            controls
                            src={videoSrc}
                            style={{
                              position: "relative",
                              width: "180px",
                              height: "180px!important",
                              border: 1,
                              borderColor: "black.300",
                              borderRadius: 2,
                              marginBottom: 2,
                              margin: "0 auto",
                            }}
                          >
                            Your browser does not support the video tag.
                          </video>
                        </ImageListItem>
                      ))}
                    </ImageList>
                    <ImageList cols={1}>
                      {filteredImages.map((img) => (
                        <ImageListItem
                          key={`media-${img}`}
                          sx={{
                            position: "relative",
                            width: "180px",
                            height: "180px!important",
                            border: 1,
                            borderColor: "black.300",
                            borderRadius: 2,
                            mb: 2,
                            margin: "0 auto",
                          }}
                        >
                          <Checkbox
                            checked={isImageSelected(img)}
                            onChange={() => toggleImageSelection(img)}
                            sx={{
                              position: "absolute",
                              top: 5,
                              right: 5,
                              zIndex: 1,
                            }}
                          />
                          <img
                            src={img}
                            alt={"item"}
                            loading="lazy"
                            onClick={() => toggleOpenedModals(img)}
                            style={{ borderRadius: 2 }}
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>

                    {openedModals.map((image, index) => (
                      <DraggableModal
                        key={`image-${image}-${index}`}
                        onClose={toggleOpenedModals}
                        imageSrc={image}
                        index={index}
                      />
                    ))}
                  </Box>
                );
              }}
            </Observer>
          </CardContent>
          <CardActions
            sx={{
              height: "8%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Observer>
              {() => (
                <Stack spacing={2}>
                  <ButtonView
                    title="pages.exam.export_image"
                    sx={{ px: 1, height: "30px" }}
                    disabled={!examStore.imagesToUpload.length}
                  />
                  <ButtonView
                    title="pages.exam.download_image"
                    onClick={examStore.downloadImages}
                    sx={{ px: 1, height: "30px" }}
                    disabled={!examStore.imagesToUpload.length}
                  />
                </Stack>
              )}
            </Observer>
          </CardActions>
        </Card>
        <ExamStreamView />
        <Card
          sx={{
            ...baseCardStyle,
            p: 0,
            flex: 1.3,
          }}
        >
          <CardContent
            sx={{
              ...cardContentStyles,
              height: "100%!important",
              p: "0",
              pb: "0!important",
            }}
          >
            <Observer>{() => <ImageControlsPanel />}</Observer>
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
}

export default ExamPage;
