import { makeAutoObservable } from "mobx";

interface ExamProps {
  filteredImages: string[];
  filteredVideos: string[];
  imagesToUpload: string[];
  openedModals: string[];
  downloadImages: () => void;
  isImageSelected: (img: string) => void;
  handleSavePreset: (img: string) => void;
  toggleImageSelection: (img: string) => void;
  toggleOpenedModals: (img: string) => void;
}

export class ExamStore implements ExamProps {
  imagesToUpload: string[] = [];
  filteredImages: string[] = [];
  filteredVideos: string[] = [];
  openedModals: string[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  toggleImageSelection(img: string) {
    if (this.imagesToUpload.includes(img)) {
      this.imagesToUpload = this.imagesToUpload.filter((item) => item !== img);
    } else {
      this.imagesToUpload.push(img);
    }
  }

  isImageSelected(img: string) {
    return this.imagesToUpload?.includes(img);
  }

  handleSavePreset = (img: string) => {
    this.filteredImages.push(img);
  };

  toggleOpenedModals(img: string) {
    if (this.openedModals.includes(img)) {
      this.openedModals = this.openedModals.filter((item) => item !== img);
    } else {
      this.openedModals.push(img);
    }
  }

  downloadImages = async () => {
    for (let i = 0; i < this.imagesToUpload.length; i++) {
      const url = this.imagesToUpload[i];

      // Fetch the image as a blob
      const response = await fetch(url);
      const blob = await response.blob();

      // Create a URL for the blob
      const blobUrl = URL.createObjectURL(blob);

      // Create a link element
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = `image-${i + 1}.png`; // Specify the filename

      // Append to body and trigger download
      document.body.appendChild(a);
      a.click();

      // Clean up
      document.body.removeChild(a);
      URL.revokeObjectURL(blobUrl);
    }
  };

  appendFilteredVideo(videoSrc: string) {
    this.filteredVideos.push(videoSrc);
  }
}
